.root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
}

.root.themeLight {
  border: 0;
  background-color: var(--color-background-light);
  color: var(--color-text-default);
}

.root.themeDark {
  border: 0;
  background-color: var(--color-background-inverted);
  color: var(--color-text-on-color);
}

.gradient {
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
}

.children {
  position: relative;
  z-index: 2;
}
