.root {
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.root.withBorder {
  padding-top: var(--space-m);
  border-top: 1px solid var(--color-border);
}

.root.hasMeta .header {
  margin-bottom: var(--space-m);
}

.title {
  margin-bottom: var(--space-xs);
}

.title + .description {
  margin-top: var(--space-m);
}

.list {
  width: 100%;
  margin-top: var(--space-l);
}

.actions {
  margin-top: var(--space-l);
}

.root.hasMeta .content {
  margin-top: var(--space-2xl);
}

/* LAYOUT VARIATIONS */
@media (--medium) {
  .root.horizontalLayout {
    display: grid;
    grid-gap: var(--column-gutter);
    grid-template-columns: repeat(12, 1fr);
  }

  .root.horizontalLayout.sticky {
    position: relative;
    align-items: start;
  }

  .root.horizontalLayout .header,
  .root.horizontalLayout .content {
    margin-top: 0;
  }

  .root.horizontalLayout .header {
    grid-column: span 6;
    margin-bottom: 0;
  }

  .root.horizontalLayout .content {
    grid-column: span 6;
  }

  .root.horizontalLayout.contentWidthWide .header {
    grid-column: span 5;
  }

  .root.horizontalLayout.contentWidthWide .content {
    grid-column: span 7;
  }

  .root.horizontalLayout.sticky .header {
    position: sticky;
    top: calc(var(--layout-sticky-top-offset) + var(--space-m));
    transition: top var(--transition-default);
  }

  .root.verticalLayout.hasMeta .header {
    margin-bottom: var(--space-2xl);
  }

  .root.horizontalLayout.offsetForEyebrow .content {
    margin-top: 1.5rem;
  }

  .title {
    padding-right: var(--space-2xl);
  }

  .description {
    max-width: 60ch;
    padding-right: var(--space-2xl-3xl);
  }
}

@media (--large) {
  .root.horizontalLayout.contentWidthWide .header {
    grid-column: span 4;
  }

  .root.horizontalLayout.contentWidthWide .content {
    grid-column: span 8;
  }
}

/* THEME VARIATIONS */
.root.hasTheme {
  margin-right: 0;
  margin-left: 0;
  padding: var(--space-2xl) var(--layout-gutter) var(--space-2xl);
  border-top: 0;
  background: var(--color-background-default);
  color: var(--color-text-default);
}

.root.hasTheme + .root.hasTheme {
  margin-top: 0;
}

.root.hasGradient {
  background-image: linear-gradient(
    to right,
    var(--color-background-default) 30%,
    var(--page-section-gradient-color) 350%
  );
}
