.root {
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-2xs) var(--space-xs);
}

@media (--medium) {
  .root {
    gap: var(--space-2xs) var(--space-m);
  }
}
